import Container_LiftedBottomAreaSkinComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/LiftedBottomAreaSkin/LiftedBottomAreaSkin.skin';


const Container_LiftedBottomAreaSkin = {
  component: Container_LiftedBottomAreaSkinComponent
};


export const components = {
  ['Container_LiftedBottomAreaSkin']: Container_LiftedBottomAreaSkin
};

